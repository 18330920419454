import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import { logout } from '../../../services/auth';

const SidebarContent = ({ onClick }) => {
  const { owner } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';

  const handleHideSidebar = () => {
    onClick();
  };

  const logoutAccount = () => {
    logout();
  };

  const goToExternalLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Home"
            route="/home"
            onClick={handleHideSidebar}
            icon="home"
          />
          <SidebarLink
            title="Operacional"
            route="/operation"
            onClick={handleHideSidebar}
            icon="users"
          />
          {![
            '1f054bcb-7500-44d4-89e5-6a6c5a4271a7',
            '689acb74-5a1e-4dc0-a410-fe1966034ebd'
          ].includes(owner) && (
            <SidebarLink
              title="Pacotes"
              route="/pricing"
              onClick={handleHideSidebar}
              icon="tag"
            />
          )}
          {/* <SidebarLink
            title="Fatura"
            route="/invoice"
            onClick={handleHideSidebar}
            icon="book"
          /> */}
          {[
            '1f054bcb-7500-44d4-89e5-6a6c5a4271a7',
            '689acb74-5a1e-4dc0-a410-fe1966034ebd'
          ].includes(owner) && (
            <SidebarLink
              title="Shoppings"
              route="/shopping"
              onClick={handleHideSidebar}
              icon="apartment"
            />
          )}
          {/* {[
            'ec8qDFWCIa6Z21q8a5XaP99daPo11mIt8oR7EnQ0',
            'QhP9bOmqkR3de2l23H7ywagJM4HZ3YMh4oy1emZT'
          ].includes(owner) && (
            <SidebarLink
              title="Controle"
              route="/control"
              onClick={handleHideSidebar}
              icon="eye"
            />
          )} */}
          <SidebarLink
            title="Suporte"
            onClick={() =>
              goToExternalLink('https://app.pipefy.com/public/form/zSLQMNot')
            }
            icon="envelope"
            externalLink
          />
          <SidebarLink
            title="Documentação"
            route="/documentation"
            onClick={handleHideSidebar}
            icon="code"
          />
        </ul>
      </div>
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Sair"
            icon="exit"
            route="/login"
            onClick={logoutAccount}
          />
        </ul>
      </div>
    </>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default SidebarContent;
