export const accessKeyMask = (accesskey) => {
    let aux = accesskey;
    aux = aux.replace(/\D/g, '');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    aux = aux.replace(/(\d{4})(\d)/, '$1 $2');
    return aux;
};

export const normalizeName = (v) => v.toLowerCase().replace(/(?:^|\s)\S/g, (s) => s.toUpperCase());


export const getStatusBadge = (status) => {
    switch (status) {
        case "Realizada": return "success";
        case "Aguardando": return "warning";
        case "Coletando": return "primary";
        default: return "primary";
    }
}

export const translatedStatus = (status) => {
    switch (status) {
        case "NA FILA": return "Na fila";
        case "FREEZER": return "Aguardando";
        case "OK": return "Realizado";
        case "COLETANDO": return "Coletando";
        default: return status;
    }
}

export const translatedStatusAPI = (status) => {
    switch (status) {
        case "Na fila": return "NA FILA";
        case "Aguardando": return "FREEZER";
        case "Realizado": return "OK";
        case "Coletando": return "COLETANDO";
        default: return status;
    }
}

export const colorsPalette = () =>
    [
        "#00afef",
        "#7bbee9",
        "#b0cde3",
        "#dddddd",
        "#a496bc",
        "#6b549b",
        "#27157a",
        "#003f5c",
        "#bc5090",
        "#ff6361",
        "#ffa600"
    ].reverse();

export const colorsPalette2 = () =>
    [
        "#67001f",
        "#b2182b",
        "#d6604d",
        "#f4a582",
        "#fddbc7",
        "#d1e5f0",
        "#92c5de",
        "#4393c3",
        "#2166ac",
        "#053061",
        "#67001f",
        "#b2182b",
        "#d6604d",
        "#f4a582",
        "#fddbc7",
        "#d1e5f0",
        "#92c5de",
        "#4393c3",
        "#2166ac",
        "#053061",
        "#67001f",
        "#b2182b",
        "#d6604d",
        "#f4a582",
        "#fddbc7",
        "#d1e5f0",
        "#92c5de",
        "#4393c3",
        "#2166ac",
        "#053061"
    ];

export const removeSpecialCharacters2 = (name) => {
    let formatName = "";
    formatName = name.replace(/[áàãâä]/g, 'a');
    formatName = name.replace(/[éèêë&]/g, 'e');
    formatName = name.replace(/[íìîï]/g, 'i');
    formatName = name.replace(/[óòõôö]/g, 'o');
    formatName = name.replace(/[úùûü]/g, 'u');
    formatName = name.replace(/[ç]/g, 'c');
    formatName = name.replace(/[!@#$%^&*-]/g, '');
    formatName = name.replace(/[^\w\s]/gi, '');
    return formatName;
}

export const paymentPackages = {
    "7YfUqRRba": { name: "pro plus", value: "R$ 150.000", formatName: "Pro Plus" },
    "7YfUqFLev": { name: "profissional", value: "R$ 100.000", formatName: "Profissional" },
    "7YfUqvbmb": { name: "avancado", value: "R$ 80.000", formatName: "Avançado" },
    "7YfUpzHHR": { name: "intermediario", value: "R$ 40.000", formatName: "Intermediário" },
    "7YfUM7NnG": { name: "basico", value: "R$ 15.000", formatName: "Basico" },
    "7YhAH5X7G": { name: "inicial", value: "R$ 2.500", formatName: "Inicial" },
    "7YfUxCTQv": { name: "teste", value: "R$ 5,00", formatName: "Teste" },
};

export const JSONA = {
    "11": { models: [], name: "RO" },
    "12": { models: [], name: "AC" },
    "13": { models: ["55", "65"], name: "AM" },
    "14": { models: [], name: "RR" },
    "15": { models: [], name: "PA" },
    "16": { models: [], name: "AP" },
    "17": { models: ["55", "65"], name: "TO" },
    "21": { models: ["55", "65"], name: "MA" },
    "22": { models: ["55", "65"], name: "PI" },
    "23": { models: [], name: "CE" },
    "24": { models: ["55", "65"], name: "RN" },
    "25": { models: ["55", "65"], name: "PB" },
    "26": { models: ["55", "65"], name: "PE" },
    "27": { models: [], name: "AL" },
    "28": { models: ["55", "65"], name: "SE" },
    "29": { models: ["55", "65"], name: "BA" },
    "31": { models: ["55", "65"], name: "MG" },
    "32": { models: ["55", "65"], name: "ES" },
    "33": { models: ["55", "65"], name: "RJ" },
    "35": { models: ["55", "59", "65"], name: "SP" },
    "41": { models: ["55", "65"], name: "PR" },
    "42": { models: ["55", "65"], name: "SC" },
    "43": { models: ["55", "65"], name: "RS" },
    "50": { models: ["55", "65"], name: "MS" },
    "51": { models: ["55", "65"], name: "MT" },
    "52": { models: ["55", "65"], name: "GO" },
    "53": { models: ["55", "65"], name: "DF" }
};

export const ShoppingCode = {
    'SAM': 'AMAZONAS',
    'SCG': 'CAMPO GRANDE',
    'SCP': 'CAMPINAS',
    'SCL': 'CATUAI LONDRINA',
    'SCM': 'CATUAI MARINGA',
    'SCT': 'CURITIBA',
    'SDR': 'DEL REY',
    'DEV': 'DESENVOLVIMENTO',
    'SET': 'ESTAÇAO',
    'SEB': 'ESTACAO BH',
    'SEC': 'ESTACAO CUIABA',
    'SGO': 'GOIANIA',
    'SIC': 'IGUATEMI CAXIAS',
    'SIS': 'INDEPENDENCIA',
    'SJS': 'JARDIM SUL',
    'SMO': 'MOOCA',
    'SMS': 'METRO SANTA CRUZ',
    'SNS': 'NORTE SHOPPING',
    'PDP': "PARQUE DOM PEDRO",
    'SPN': 'PLAZA NITEROI',
    'SPC': 'PIRACICABA',
    'SLB': 'LEBLON',
    'SRA': 'RIO ANIL',
    'SSB': 'SAO BERNARDO',
    'STB': 'TAMBORÉ',
    'STJ': 'TIJUCA',
    'SUB': 'UBERLÂNDIA',
    'SVL': 'VILLA LOBOS',
    'SVV': 'VILA VELHA',
    'undefined': 'SEM REGISTRO'
}

export const inputAccessKeyMask = () =>
    [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ];