import React, { useEffect, useState } from 'react';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { Card, CardBody, Col, Row } from 'reactstrap';
import axios from 'axios';
import { PieChart, Pie } from 'recharts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import CountRequestType from './CountRequestType';
import { getToken } from '../../../services/auth';

const pieChartData = [
  { value: 0, fill: '#142357' },
  { value: 100, fill: '#f2f4f7' }
];

const request = {
  captcha: 0,
  cpf: 0,
  hired: 0,
  price: 0,
  profile: 0,
  reader: 0,
  used: 0
};

const CountRequest = () => {
  const [data, setData] = useState(request);
  const [dataGraph, setDataGraph] = useState(pieChartData);
  const [refresh, setRefresh] = useState(false);

  const fetchData = async () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    setRefresh(true);
    const apiUrl = `${process.env.REACT_APP_WAS_KEY}/requestCount`;
    const res = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${getToken()}` },
      cancelToken: source.token
    });
    setDataGraph([
      { value: res.data.used, fill: '#142357' },
      { value: (res.data.hired - res.data.used), fill: '#f2f4f7' }
    ]);
    setData(res.data);
    setRefresh(false);
    return () => {
      source.cancel();
    };
  };

  useEffect(() => {
    fetchData().catch((e) => {
      throw e;
    });
  }, []);

  return (
    <>
      <Col xs={12} md={12} xl={4}>
        <Card>
          <CardBody className="panel__body">
            {refresh ? (
              <div className="panel__refresh">
                <LoadingIcon />
              </div>
            ) : (
              ''
            )}
            <div className="panel__btns">
              <button
                className="panel__btn"
                aria-label="panel__btn"
                type="button"
                onClick={fetchData}
              >
                <AutorenewIcon />
              </button>
            </div>
            <div className="panel__title" style={{ marginBottom: '10px' }}>
              <h5 className="bold-text">Consumo de requisições</h5>
            </div>
            <div className="dashboard__current-users">
              <div className="dashboard__current-users-chart">
                <PieChart height={150} width={280}>
                  <Pie
                    data={dataGraph}
                    dataKey="value"
                    cx={135}
                    cy={140}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={100}
                    outerRadius={128}
                    paddingAngle={0}
                  />
                </PieChart>
                <p className="dashboard__current-users-label">{data.used}</p>
              </div>
              <div className="dashboard__current-users-info" dir="ltr">
                <p className="dashboard__current-users-day">
                  <span>Requisições Min</span>
                  <span>0</span>
                </p>
                <p className="dashboard__current-users-day">
                  <span>Requisições Max</span>
                  <span>{data.hired}</span>
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xs={12} md={12} xl={8}>
        <Row>
          <Col xs={12} md={12} xl={6}>
            <CountRequestType
              title="Leitura De Nota"
              value={data.reader}
              percent={+((data.reader * 100) / data.used).toFixed() || 0}
              loading={refresh}
            />
          </Col>
          <Col xs={12} md={12} xl={6}>
            <CountRequestType
              title="Consulta de CPF"
              value={data.cpf}
              percent={+((data.cpf * 100) / data.used).toFixed() || 0}
              loading={refresh}
            />
          </Col>
          <Col xs={12} md={12} xl={6}>
            <CountRequestType
              title="Precificação"
              value={data.price}
              percent={+((data.price * 100) / data.used).toFixed() || 0}
              loading={refresh}
            />
          </Col>
          <Col xs={12} md={12} xl={6}>
            <CountRequestType
              title="Relátorio de Perfil"
              value={data.profile}
              percent={+((data.profile * 100) / data.used).toFixed() || 0}
              loading={refresh}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default CountRequest;
